
import ShopButton from "../buttons/ShopButton";
import ShopSkeleton from "@/layouts/shop/components/skeleton/ShopSkeleton";
import ShoppingListAddProductButton
  from "@/layouts/shop/components/buttons/product/shoppinglist/ShoppingListAddProductButton";
import ProductCartButtons from "@/layouts/shop/components/buttons/product/cart/ProductCartButtons.vue";

export default {
  name: "ProductButtons",
  components: { ProductCartButtons, ShoppingListAddProductButton, ShopSkeleton, ShopButton },
  props: {
    product: {
      type: Object,
      required: true
    },
    showFavoriteButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openShoppingListModal() {
      this.$store.commit('shoppingLists/setSelectedProduct', this.product);
    }
  },
  computed: {
    shouldShowFavoriteButton() {
      return this.isAuthenticated && this.showFavoriteButton;
    },
    isAuthenticated() {
      return this.$auth.loggedIn;
    }
  },
}
