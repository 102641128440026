
import ProductButtons from "./ProductButtons";
import ProductCardOverlay from "@/layouts/shop/components/products/ProductCardOverlay";
import ProductCardInfoBadge from "@/layouts/shop/components/products/ProductCardInfos/ProductCardInfoBadge";
import ProductCardInfoBadgeList from "@/layouts/shop/components/products/ProductCardInfos/ProductCardInfoBadgeList";
import ProductCardInfoExplanationList
  from "@/layouts/shop/components/products/ProductCardInfos/ProductCardInfoExplanationList";
import ProductCardImage from "@/layouts/shop/components/products/ProductCardInfos/ProductCardImage/ProductCardImage";
import ProductCardShortDateBadge
  from "@/layouts/shop/components/products/ProductCardInfos/ProductCardImage/ProductCardShortDateBadge";
import ProductCardGiftCampaignBadge
  from "@/layouts/shop/components/products/ProductCardInfos/ProductCardImage/ProductCardGiftCampaignBadge";
import ProductPriceDisplay from "@/layouts/shop/components/products/ProductCardInfos/Price/ProductPriceDisplay";
import ProductRibbon from "@/layouts/shop/components/products/ProductRibbon/ProductRibbon";
import viewportMixin from "@/mixins/viewportMixin";
import ProductCardInfoContents from "@/layouts/shop/components/products/ProductCardInfos/ProductCardInfoContents";
import NuxtProductPageLink from "@/layouts/shop/components/products/NuxtProductPageLink.vue";

export default {
  name: "ProductCard",
  components: {
    NuxtProductPageLink,
    ProductCardInfoContents,
    ProductRibbon,
    ProductPriceDisplay,
    ProductCardGiftCampaignBadge,
    ProductCardShortDateBadge,
    ProductCardImage,
    ProductCardInfoExplanationList,
    ProductCardInfoBadgeList,
    ProductCardInfoBadge,
    ProductCardOverlay,
    ProductButtons
  },
  mixins: [viewportMixin],
  props: {
    /**
     * Product object. Could also be passed as a JSON encoded string, because the BlogPostTextRender
     * component is not able to pass it as an object.
     */
    product: {
      type: [Object, String],
      default: () => {
      }
    },
  },
  data: () => ({
    hovered: false
  }),
  computed: {
    getProduct() {
      if (typeof this.product === "string") {
        return JSON.parse(this.product);
      }

      return this.product;
    },
    getSellingData() {
      return this.getProduct.sellingData;
    },
    getSellingPrice() {
      return this.getSellingData.price;
    },
    getCampaigns() {
      return this.product.campaigns;
    },
    isDiscounted() {
      const dp = this.getCampaigns?.discountedPrice || 0;
      return dp !== null && dp > 0;
    },
    isNewProduct() {
      return this.product.isNew;
    },
    hasCampaignsToDisplay() {
      return this.getCampaigns?.toDisplay?.length > 0 || false;
    },
    getCampaignsToDisplay() {
      if(!this.hasCampaignsToDisplay)
        return [];

      return this.getCampaigns.toDisplay;
    },
    isShortDate() {
      return this.getCampaigns?.isShortDate ?? false;
    },
    isPromoted() {
      return this.getProduct.promoted || false;
    },
  },
}
