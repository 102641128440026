
import ProductCardInfoExplanation from "@/layouts/shop/components/products/ProductCardInfos/ProductCardInfoExplanation";
import ProductCardInfoExplanationList
  from "@/layouts/shop/components/products/ProductCardInfos/ProductCardInfoExplanationList";
import ProductPriceDisplay from "@/layouts/shop/components/products/ProductCardInfos/Price/ProductPriceDisplay";
import ProductCardInfoPricePerUnit
  from "@/layouts/shop/components/products/ProductCardInfos/ProductCardInfoPricePerUnit";
import ProductCardInfoContents from "@/layouts/shop/components/products/ProductCardInfos/ProductCardInfoContents";
import NuxtProductPageLink from "@/layouts/shop/components/products/NuxtProductPageLink.vue";
import ProductCardGiftCampaignBadge
  from "@/layouts/shop/components/products/ProductCardInfos/ProductCardImage/ProductCardGiftCampaignBadge.vue";

export default {
  name: "ProductCardOverlay",
  components: {
    ProductCardGiftCampaignBadge,
    NuxtProductPageLink,
    ProductCardInfoContents,
    ProductCardInfoPricePerUnit,
    ProductPriceDisplay,
    ProductCardInfoExplanationList,
    ProductCardInfoExplanation
  },
  props: {
    product: {
      type: Object,
      default: () => {
      }
    },
  },
  computed: {
    getSellingData() {
      return this.product.sellingData;
    },
    getSellingPrice() {
      return this.getSellingData.price;
    },
    getSellingPricePerUnit() {
      return this.product.campaigns?.discountedPricePerUnit || this.product.sellingData.pricePerUnit.price;
    },
    getCountryOfOrigin() {
      return this.product.countryOfOrigin;
    },

    getCampaigns() {
      return this.product.campaigns;
    },
    hasCampaignsToDisplay() {
      return this.getCampaigns?.toDisplay?.length > 0 || false;
    },
    getCampaignsToDisplay() {
      if(!this.hasCampaignsToDisplay)
        return [];

      return this.getCampaigns.toDisplay;
    },
  }
}
