
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";
import ShopInput from "@/layouts/shop/components/forms/inputs/ShopInput.vue";
import {debounce} from "@/helpers/debounceHelper.js";
import QuantitySelector from "@/layouts/shop/components/quantitySelector/QuantitySelector.vue";

export default {
  name: "AddModifyCartQuantityProductButton",
  components: {QuantitySelector, ShopInput, ShopButton},
  props: {
    product: {
      type: Object,
      required: true
    },
    showNotInCartButton: {
      type: Boolean,
      default: true
    },
    usedInCart: {
      type: Boolean,
      default: false
    },
    flexCol: {
      type: Boolean,
      default: false
    },
    showInput: {
      type: Boolean,
      default: true
    },
    maxAvailableQuantity: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    userIsAuthenticated() {
      return this.$auth.loggedIn;
    },
    getMessageForMaxBuyableQuantity() {
      const maxPerOrder = this.product.maxQtyPerOrder || 99;
      const maxAvailable = this.product.maxBuyableQuantity || 99;

      if (maxAvailable < maxPerOrder) {
        return this.$t('product.error_max_stock_quantity', {max_stock_quantity: maxAvailable});
      } else {
        return this.$t('product.error_max_buyable_quantity', {max_buyable_quantity: maxPerOrder});

      }
    },
    getMaxBuyableQuantity() {
      const maxAvailable = this.maxAvailableQuantity || 99;
      const maxBuyable = this.product.maxBuyableQuantity || 99;

      return Math.min(maxAvailable, maxBuyable);
    },
    getIsItemInCart() {
      return this.itemQuantityInCart > 0;
    },
    itemQuantityInCart: {
      get() {
        if (!this.userIsAuthenticated) {
          return this.$store.getters["guestCart/getItemQuantityInCart"](this.product.id);
        }

        return this.$store.getters["cart/getItemQuantityInCart"](this.product.id);
      },
      set(quantity) {
        if (this.isLoading
            || this.$store.getters["cart/isCartLoading"]
            || this.$store.getters["guestCart/isCartLoading"]) {
          return false;
        }

        if (this.userIsAuthenticated) {
          this.$store.commit('cart/setCartItemQuantity', {
            itemId: this.product.id,
            quantity
          });
        } else {
          this.$store.commit('guestCart/setCartItemQuantity', {
            itemId: this.product.id,
            quantity
          });
        }

        if (this.product.algoliaObjectId !== undefined && this.product.algoliaObjectId !== null && typeof aa === 'function') {
          aa("addedToCartObjectIDsAfterSearch", {
            index: "products",
            eventName: "Add to cart",
            objectIDs: [this.product.algoliaObjectId]
          });
        }

        this.syncCartItemQuantityWithBackend();
      }
    },

  },
  methods: {
    addToCartInitially() {
      this.itemQuantityInCart = 1;

      // Set Focus on Input Field after adding a product to the cart.
      this.$nextTick(() => {
        if(this.$refs.quantitySelector && typeof this.$refs.quantitySelector.setQuantityInputFocus === "function") {
          this.$refs.quantitySelector.setQuantityInputFocus();
        }
      });
    },
    displayErrorMessage() {
      this.$modal.show({
        type: 'danger',
        title: this.$t('common.error'),
        body: this.getMessageForMaxBuyableQuantity,
        primary: {
          label: 'OK',
          theme: 'danger',
          action: () => this.$emit('disabled-confirm'),
        },
      });
    },
    syncCartItemQuantityWithBackend: debounce(function () {
      this.isLoading = true;

      if (this.userIsAuthenticated) {
        this.$store.dispatch('cart/setCartItemQuantity', {
          itemId: this.product.id,
          quantity: this.itemQuantityInCart
        }).finally(() => {
          this.isLoading = false;

        })
      } else {
        this.$store.dispatch('guestCart/setCartItemQuantity', {
          itemId: this.product.id,
          quantity: this.itemQuantityInCart
        }).finally(() => {
          this.isLoading = false;
        })
      }
    }, 600),
  },
}
