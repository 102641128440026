
import ShopSidebarCategory from "./ShopSidebarCategory";
import { mapGetters } from "vuex";
import ShopSidebarSimpleLink from "@/layouts/shop/partials/sidebar/ShopSidebarSimpleLink";
import ShopMobileCategory from "@/layouts/shop/partials/mobile/category/ShopMobileCategory.vue";

export default {
  name: "ShopSidebarMenu",
  components: {ShopMobileCategory, ShopSidebarSimpleLink, ShopSidebarCategory },
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    categories: []
  }),
  computed: {
    ...mapGetters('sidebar', ['getCategories'])
  },
}
