
export default {
  name: "BannerLink",
  props: {
    banner: { type: Object, required: true }
  },
  computed: {
    isExternalLink() {
      return this.banner.link.url !== undefined;
    },
    getExternalLink() {
      if(!this.isExternalLink) {
        return null;
      }
      return this.banner.link.url;
    },
    getInternalLink() {
      if(this.isExternalLink) {
        return null;
      }

      return this.localePath({
        name: this.$pageDataHelper.getRouteNameByPageIdentifier(this.banner.link),
        params: this.banner.link.params
      })
    }
  }
}
