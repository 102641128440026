
export default {
  name: "NuxtProductPageLink",
  props: {
    productId: {
      type: Number,
      required: true
    },
    productSlug: {
      type: String,
      required: true
    },
    algoliaObjectId: {
      type: String,
      required: false
    }
  },
  methods: {
      clickAction() {
        if (this.algoliaObjectId !== undefined && this.algoliaObjectId !== null && typeof aa === 'function') {
          aa("clickedObjectIDsAfterSearch", {
            index: "products",
            eventName: "View product",
            objectIDs: [this.algoliaObjectId]
          });
        }
      }
  },
  computed: {
    getProductPageLink() {
      return this.localePath({
        name: 'products-id-slug',
        params: {
          id: this.productId,
          slug: this.productSlug
        }
      });
    }
  },
}
