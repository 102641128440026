
export default {
  name: 'ShopMobileCategory',
  props: {
    category: {
      type: Object,
      required: true
    },
  },
  computed: {
    hasChildren() {
      return this.category.subCategories.length > 0;
    },
    isExpanded() {
      return this.$store.getters['sidebar/isExpanded'](this.category.id);
    },
    getCategoryLink() {
      return this.localePath({
        name: 'categories-id-slug',
        params: {
          id: this.category.id,
          slug: this.category.slug,
        }
      })
    }
  },
  methods: {
    expand() {
      if (this.hasChildren) {
        this.$store.commit('sidebar/toggleExpandCategory', this.category.id);
      }
    },
  }
}
