
import ShopInput from "@/layouts/shop/components/forms/inputs/ShopInput.vue";
import ShopButton from "@/layouts/shop/components/buttons/ShopButton.vue";

export default {
  name: "QuantitySelector",
  components: {ShopButton, ShopInput},
  props: {
    value: { type: Number },
    max: { type: Number, default: null },
    small: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    showInput: { type: Boolean, default: true },
    maxQuantityReachedErrorMessage: { type: String },
    flexMode: {
      type: String,
      default: "row",
      validator: val => ["row", "col"].includes(val)
    },
    useTrashIcon: {
      type: Boolean,
      default: true
    },
    disableMinusButtonOnZero: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    increaseQuantity() {
      if (this.hasReachedMaxQuantity) {
        return false;
      }
      this.setModelAndFocus(this.model + 1);
    },
    decreaseQuantity() {
      let quantity = this.model - 1;
      this.setModelAndFocus(quantity < 0 ? 0 : quantity);
    },
    setModelAndFocus(value) {
      this.model = value;

      this.$nextTick(() => {
        if(value > 0) {
          this.setQuantityInputFocus();
        }
      })
    },
    setQuantityInputFocus() {
      if(this.$refs.quantityInput) {
        this.$refs.quantityInput.focusInput();
        this.$emit('focus');
      }
    },
    displayErrorMessage() {
      this.$emit('maxExceeded');
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },

    hasReachedMaxQuantity() {
      if(this.max === null) {
        return false;
      }

      return this.value >= this.max;
    },

    getIconForMinus() {
      if (this.model <= 1 && this.useTrashIcon) {
        return "trash";
      }

      return "minus";
    },

    isFlexModeCol() {
      return this.flexMode === "col";
    }
  },
}
